import {
    GET_WALLET_BALANCE
  } from "../types";
  
  //default state
  const initialState = {
    wallet_balance: 0
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_WALLET_BALANCE:
        return {
          ...state,
          wallet_balance : action.payload,
        };
        
      default: return state;
    }
  }
  